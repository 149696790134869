import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["checkbox", "content", "expand"];

  connect() {
    if (this.isDisabled) {
      this.checkboxTarget.checked = true;
    } else {
      this.displayExpandIfNeeded();
      // Connect can run before clamp on the first page render
      requestAnimationFrame(this.displayExpandIfNeeded);
    }
  }

  displayExpandIfNeeded = () => {
    if (this.isClamped) {
      this.expandTarget.classList.remove("hidden");
    } else {
      this.expandTarget.classList.add("hidden");
    }
  };

  get isClamped() {
    return this.contentTarget.scrollHeight > this.contentTarget.clientHeight;
  }

  get isDisabled() {
    return (
      this.element.closest("[data-disable-truncate]")?.dataset
        .disableTruncate === "true"
    );
  }
}
