import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    eventName: String,
  };

  connect() {
    const event = new CustomEvent(this.eventNameValue);
    window.dispatchEvent(event);
    this.element.remove();
  }
}
