import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "writeTab",
    "previewTab",
    "writePanel",
    "previewPanel",
    "preview",
  ];
  static classes = ["activeTab", "inactiveTab"];
  static values = {
    previewUrl: String,
    tab: String,
  };

  connect() {
    this.cache = new Map([["", "Nothing to preview"]]);
    this.activeRequests = new Map();
  }

  changeTab({ params: { tab } }) {
    this.tabValue = tab;
  }

  tabValueChanged(tab) {
    if (tab === "write") {
      this.showWrite();
    } else if (tab === "preview") {
      this.showPreview();
    }
  }

  showWrite() {
    this.deactivateTab(this.previewTabTarget);
    this.activateTab(this.writeTabTarget);
    this.writePanelTarget.classList.remove("hidden");
    this.previewPanelTarget.classList.add("hidden");
  }

  showPreview() {
    this.refreshPreview();
    this.previewPanelTarget.style.minHeight = `${this.writePanelTarget.offsetHeight}px`;
    this.deactivateTab(this.writeTabTarget);
    this.activateTab(this.previewTabTarget);
    this.writePanelTarget.classList.add("hidden");
    this.previewPanelTarget.classList.remove("hidden");
  }

  activateTab(tab) {
    tab.classList.remove(...this.inactiveTabClasses);
    tab.classList.add(...this.activeTabClasses);
  }

  deactivateTab(tab) {
    tab.classList.remove(...this.activeTabClasses);
    tab.classList.add(...this.inactiveTabClasses);
  }

  async refreshPreview() {
    const text = this.inputText;

    this.fetchPreview(text);

    if (this.cache.has(text)) {
      this.previewValue = this.cache.get(text);
    }

    if (this.activeRequests.has(text)) {
      await this.activeRequests.get(text);
      this.previewValue = this.cache.get(text);
    }
  }

  prefetchPreview() {
    this.fetchPreview(this.inputText);
  }

  fetchPreview(text) {
    if (this.cache.has(text)) return;

    this.cache.set(text, "Loading...");

    this.activeRequests.set(
      text,
      (async () => {
        const response = await post(this.previewUrlValue, { body: { text } });

        if (response.ok) {
          this.cache.set(text, await response.html);
        } else {
          this.cache.set(text, "Error");
        }

        this.activeRequests.delete(text);
      })()
    );
  }

  get inputText() {
    return this.writePanelTarget.querySelector("textarea").value;
  }

  set previewValue(value) {
    this.previewTarget.innerHTML = value;
  }
}
