import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["menu"];

  connect() {
    this.scrolled = false;
    this.lastScrollY = window.scrollY;
    this.isHidden = false;
    this.navbarHeight = this.element.offsetHeight;
    this.handleScroll();
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  disconnect() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  // * Here we handle the sticky navbar - hide/show on scroll

  handleScroll() {
    const currentScrollY = window.scrollY;
    const hasScrolled = currentScrollY > 0;

    if (currentScrollY > this.lastScrollY) {
      this.onScrollDown(currentScrollY);
    } else {
      this.onScrollUp(currentScrollY);
    }

    if (hasScrolled && !this.scrolled) {
      this.scrolled = true;
      this.toggleSticky();
    } else if (!hasScrolled && this.scrolled) {
      this.scrolled = false;
      this.toggleSticky();
    }

    this.lastScrollY = currentScrollY;
  }

  onScrollDown(currentScrollY) {
    if (currentScrollY > this.navbarHeight && !this.isHidden) {
      this.hideNavbar();
    }
  }

  onScrollUp() {
    this.showNavbar();
  }

  toggleSticky() {
    const classes = [
      "lg:relative",
      "sticky",
      "top-0",
      "border-b",
      "border-gray-100",
      "bg-white/60",
      "backdrop-blur",
    ];

    classes.forEach((className) => {
      this.element.classList.toggle(className);
    });
  }

  hideNavbar() {
    this.element.classList.add("-translate-y-full");
    this.isHidden = true;
  }

  showNavbar() {
    this.element.classList.remove("-translate-y-full");
    this.isHidden = false;
  }

  // * Only for mobile menu

  toggle() {
    const menu = this.menuTarget;
    const isExpanded = menu.getAttribute("aria-expanded") === "true";

    if (isExpanded) {
      this.closeMenu();
    } else {
      this.openMenu();
    }

    this.toggleBodyOverflow();
  }

  openMenu() {
    this.menuTarget.classList.add("z-50");
    this.menuTarget.classList.remove("hidden");

    setTimeout(() => {
      this.menuTarget.setAttribute("aria-expanded", "true");
    }, 50);
  }

  closeMenu() {
    this.menuTarget.classList.remove("translate-x-0");
    this.menuTarget.setAttribute("aria-expanded", "false");

    setTimeout(() => {
      this.menuTarget.classList.add("hidden");
      this.menuTarget.classList.remove("z-50");
    }, 500);
  }

  toggleBodyOverflow() {
    document.body.classList.toggle("overflow-hidden");
  }
}
