import { application } from "./application";
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers";
import Timeago from "stimulus-timeago";
import TextareaAutogrow from "stimulus-textarea-autogrow";
import { FormSaveController } from "stimulus-library";
import AcceptanceCriteriaController from "./acceptance_criteria_controller.js";
import LimitBusinessContextController from "./limit_business_context_controller.js";
import DragController from "./drag_controller.js";
import DatePicker from "./datepicker_controller.js";
import StickyController from "./sticky_controller.js";
import Dropdown from "./dropdown_controller.js";
import Autosave from "./autosave_controller.js";
import ScrollTo from "@stimulus-components/scroll-to";
import Carousel from "@stimulus-components/carousel";
import "swiper/css/bundle";

import "trix";
import "@rails/actiontext";

const context = require.context("controllers", true, /\.js$/);
const componentsContext = require.context(
  "../../components",
  true,
  /_controller\.js$/
);
const componentsDefinitions = definitionsFromContext(componentsContext).map(
  (definition) => {
    const identifier = definition.identifier.replace("--component", "");
    return { ...definition, identifier };
  }
);

document.addEventListener("turbo:before-visit", () => {
  const frame = document.querySelector("turbo-frame#resume-preview");

  if (!frame) return;

  frame.innerHTML = "";
});

application.load(definitionsFromContext(context).concat(componentsDefinitions));
application.register("autosave", Autosave);
application.register("dropdown", Dropdown);
application.register("timeago", Timeago);
application.register("textarea-autogrow", TextareaAutogrow);
application.register("form-save", FormSaveController);
application.register("acceptance-criteria", AcceptanceCriteriaController);
application.register("limit-business-context", LimitBusinessContextController);
application.register("drag", DragController);
application.register("datepicker", DatePicker);
application.register("carousel", Carousel);
application.register("sticky", StickyController);
application.register("scroll-to", ScrollTo);
