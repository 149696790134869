import Datepicker from "stimulus-datepicker";
import { createPopper } from "@popperjs/core";
export default class extends Datepicker {
  connect() {
    super.connect();

    this.scrollContainer = this.getScrollContainer(this.element);
  }

  disconnect() {
    this.destroyPopperInstance();
  }

  resetValue() {
    this.dateValue = "";
    this.inputTarget.value = "";
  }

  createPopperInstance() {
    if (!this.inputTarget && !this.calendarTarget) return;

    this.popperInstance = createPopper(this.inputTarget, this.calendarTarget, {
      placement: "bottom-start",
      modifiers: [
        {
          name: "offset",
          options: {
            offset: [0, 12],
          },
        },
        {
          name: "preventOverflow",
          options: {
            rootBoundary: "viewport",
            altAxis: true,
            padding: 12,
          },
        },
      ],
    });
  }

  destroyPopperInstance() {
    if (!this.popperInstance) return;

    this.popperInstance.destroy();
    this.popperInstance = null;
  }

  hideElementWithAnimation(element) {
    element.classList.add("hide-calendar");

    if (this.hasCssAnimation(element)) {
      element.onanimationend = (e) => e.target.remove();
    } else {
      element.remove();
    }
  }

  closeInnerDatepicker() {
    const innerDatepicker = document.querySelectorAll(
      'div[data-datepicker-target="calendar"]'
    );

    if (innerDatepicker.length === 0) return;

    innerDatepicker.forEach((datepicker) => {
      this.hideElementWithAnimation(datepicker);

      const datepickerButton = datepicker.parentElement.querySelector(
        'button[data-datepicker-target="toggle"]'
      );

      if (!datepickerButton) return;

      this.styleToggleButton(datepickerButton, false);
    });
  }

  openOnClick(event) {
    event.preventDefault();
    event.stopPropagation();

    if (this.hasCalendarTarget) return;

    this.open(true);
  }

  open(animate, isoDate = this.initialIsoDate()) {
    this.styleToggleButton(this.toggleTarget, true);

    this.closeInnerDatepicker();

    super.open(animate, isoDate);

    // Override the default animation, because it use popper.js to position the calendar
    if (animate) {
      this.calendarTarget.classList.add("opacity-0");
    }

    this.createPopperInstance();
    this.popperInstance.update();

    if (this.scrollContainer) {
      this.scrollContainer.classList.add("overflow-hidden");
    }

    if (animate) {
      setTimeout(() => {
        this.calendarTarget.classList.remove("opacity-0");
      }, 100);

      this.calendarTarget.classList.add("fade-in");
      this.calendarTarget.onanimationend = () =>
        this.calendarTarget.classList.remove("fade-in");
    }

    this.inputTarget.focus();
  }

  close(animate) {
    this.styleToggleButton(this.toggleTarget, false);

    if (this.scrollContainer) {
      this.scrollContainer.classList.remove("overflow-hidden");
    }

    if (animate) {
      this.hideElementWithAnimation(this.calendarTarget);
    } else {
      this.calendarTarget.remove();
    }
  }

  styleToggleButton(target, open) {
    target.classList.toggle("text-blue-500", open);
    target.classList.toggle("text-gray-500", !open);
  }

  getScrollContainer(element) {
    let parent = element.parentElement;

    while (parent) {
      const overflowY = window.getComputedStyle(parent).overflowY;

      if (["auto", "scroll"].includes(overflowY)) {
        return parent;
      }

      parent = parent.parentElement;
    }

    return null;
  }
}
