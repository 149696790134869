import { Controller } from "@hotwired/stimulus";
import textMask from "vanilla-text-mask";

const masks = {
  phone: [
    "(",
    "+",
    "4",
    "8",
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
    " ",
    /\d/,
    /\d/,
    /\d/,
  ],
};

export default class extends Controller {
  static values = { mask: String };

  connect() {
    this.applyMask();
    this.element.addEventListener("focus", this.focusHandler.bind(this));
    this.element.addEventListener("blur", this.blurHandler.bind(this));
    this.element.addEventListener("input", this.inputHandler.bind(this));
    this.element.addEventListener("paste", this.pasteHandler.bind(this));
  }

  disconnect() {
    this.element.removeEventListener("focus", this.focusHandler);
    this.element.removeEventListener("blur", this.blurHandler);
    this.element.removeEventListener("input", this.inputHandler);
    this.element.removeEventListener("paste", this.pasteHandler);
  }

  blurHandler() {
    const value = this.element.value.trim();

    if (value === this.getDefaultMask()) {
      this.element.value = "";
    }
  }

  focusHandler() {
    this.showMaskIfEmpty();
    this.setCursorPosition();
  }

  pasteHandler(event) {
    event.preventDefault();

    const clipboardData = event.clipboardData || window.clipboardData;
    let pastedData = clipboardData.getData("Text").trim();

    if (!pastedData) return;

    // Remove prefix from pasted data
    const prefixRegex = /^(\(\+\d{1,3}\)|\+\d{1,3})\s*/;
    const match = pastedData.match(prefixRegex);
    const prefixLength = match ? match[0].length : 0;

    if (prefixLength) {
      pastedData = pastedData.slice(prefixLength);
    }

    // Remove all non-digit characters
    pastedData = pastedData.replace(/[^\d]/g, "");

    if (!pastedData) return;

    const defaultMask = this.getDefaultMask();

    // Replace mask placeholders with pasted data
    const formattedValue = defaultMask.split("").reduce((acc, char) => {
      if (char === "_" && pastedData.length) {
        acc += pastedData[0];
        pastedData = pastedData.slice(1);
      } else {
        acc += char;
      }

      return acc;
    });

    this.element.value = formattedValue;

    this.setCursorPosition();
  }

  setCursorPosition() {
    const value = this.element.value.trim();

    if (value.length === 0) return;

    const underscoreIndex = value.indexOf("_");

    if (underscoreIndex === -1) return;

    const formattedValue = value.slice(0, underscoreIndex);

    this.element.setSelectionRange(
      formattedValue.length,
      formattedValue.length
    );
  }

  inputHandler(event) {
    const { value } = event.target;

    if (value === this.getDefaultMask()) return;

    if (value.length === 0) {
      this.showMask();
      this.setCursorPosition();
      return;
    }
  }

  showMaskIfEmpty() {
    if (this.element.value.trim()) return;

    this.showMask();
  }

  getDefaultMask() {
    const mask = this.getMask();

    if (!mask) return;

    return mask.map((char) => (char instanceof RegExp ? "_" : char)).join("");
  }

  showMask() {
    const mask = this.getMask();

    if (!mask) return;

    this.element.value = this.getDefaultMask();
  }

  applyMask() {
    const mask = this.getMask();

    if (!mask) return;

    textMask({
      inputElement: this.element,
      mask: mask,
    });
  }

  getMask() {
    return masks[this.maskValue] || null;
  }
}
