import { Controller } from "@hotwired/stimulus";
import { currentUserId } from "students/utils";

export default class extends Controller {
  static classes = ["voted", "notVoted"];
  static values = {
    userId: String,
    hasVoted: Boolean,
  };
  static targets = ["count"];

  connect() {
    // Disable likes for broadcasted self-created tils
    if (this.authoredByCurrentUser) {
      this.element.disabled = true;
    }

    if (this.hasVotedValue) {
      this.setVotedClass();
    } else {
      this.setNotVotedClass();
    }
  }

  optimisticUpdate() {
    if (this.hasVotedValue) {
      this.setNotVotedClass();
      this.countTarget.innerHTML = this.numberOfVotes - 1;
    } else {
      this.setVotedClass();
      this.countTarget.innerHTML = this.numberOfVotes + 1;
    }
  }

  setVotedClass() {
    this.element.classList.remove(...this.notVotedClasses);
    this.element.classList.add(...this.votedClasses);
  }

  setNotVotedClass() {
    this.element.classList.remove(...this.votedClasses);
    this.element.classList.add(...this.notVotedClasses);
  }

  get numberOfVotes() {
    return Number(this.countTarget.innerText);
  }

  get authoredByCurrentUser() {
    return this.userIdValue === currentUserId();
  }
}
