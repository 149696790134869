import { Autosave } from "tailwindcss-stimulus-components";

const SUCCESS_ICON = `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="16" height="16" rx="2" fill="#EDF9F2"/>
<path d="M5 8.41872L7.2944 10.7109L11.7454 6" stroke="#47BF7C" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
`;

const ERROR_ICON = `<svg  xmlns="http://www.w3.org/2000/svg"  width="16"  height="16"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0" /><path d="M12 9v4" /><path d="M12 16v.01" /></svg>`;

const SUBMITTING_ICON = `<svg  xmlns="http://www.w3.org/2000/svg"  width="16"  height="16"  viewBox="0 0 24 24"  fill="none"  stroke="currentColor"  stroke-width="2"  stroke-linecap="round"  stroke-linejoin="round"  class="animate-spin"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M12 6l0 -3" /><path d="M16.25 7.75l2.15 -2.15" /><path d="M18 12l3 0" /><path d="M16.25 16.25l2.15 2.15" /><path d="M12 18l0 3" /><path d="M7.75 16.25l-2.15 2.15" /><path d="M6 12l-3 0" /><path d="M7.75 7.75l-2.15 -2.15" /></svg>`;

// * Autosave controller with focus restore
// * This controller extends the Autosave controller from tailwindcss-stimulus-components
export default class extends Autosave {
  static targets = ["form", "status"];

  static values = {
    restoreFocus: { type: Boolean, default: true },
  };

  connect() {
    super.connect();

    if (this.restoreFocusValue) {
      this.startObserving();
    }
  }

  disconnect() {
    super.disconnect();

    if (this.restoreFocusValue) {
      this.stopObserving();
    }
  }

  startObserving() {
    this.mutationObserver = new MutationObserver((mutationsList) => {
      for (const mutation of mutationsList) {
        if (mutation.target === this.element) {
          this.restoreFocus();
        }
      }
    });

    this.mutationObserver.observe(this.element, {
      childList: true,
    });
  }

  stopObserving() {
    if (!this.mutationObserver) return;

    this.mutationObserver.disconnect();
  }

  saveFocus() {
    this.lastFocusedElement = document.activeElement;
  }

  restoreFocus() {
    if (!this.lastFocusedElement) return;

    const { name } = this.lastFocusedElement;
    const element = this.element.querySelector(`[name='${name}']`);
    if (!element) return;

    element.focus();

    // Skip if the element is a masked input, as it will handle the cursor position
    if (element.getAttribute("data-controller") === "masked-input") return;

    // Restore the cursor position
    const value = this.lastFocusedElement.value;
    const length = value.length;
    element.setSelectionRange(length, length);
  }

  handleResponse(event) {
    const { status } = event.detail.fetchResponse.response;

    if (status === 200) {
      this.setStatus(this.successTextValue, SUCCESS_ICON);
    } else {
      this.setStatus(this.errorTextValue, ERROR_ICON);
    }
  }

  save() {
    clearTimeout(this.timeout);
    this.clearStyles();
    this.statusTarget.innerHTML = `${SUBMITTING_ICON} ${this.submittingTextValue}`;

    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit();
    }, this.submitDurationValue);
  }

  setStatus(message, icon = "") {
    this.clearStyles();

    this.statusTarget.innerHTML = `${icon} ${message}`;
    if (message === this.successTextValue) {
      this.statusTarget.classList.add("text-green-500");
    }

    if (message === this.errorTextValue) {
      this.statusTarget.classList.add("text-red-500");
    }

    this.timeout = setTimeout(() => {
      this.statusTarget.textContent = "";
      this.clearStyles();
    }, this.statusDurationValue);
  }

  clearStyles() {
    this.statusTarget.classList.remove("text-green-500");
    this.statusTarget.classList.remove("text-red-500");
  }
}
